<template>
  <div>
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
    <CModalExtended
      size="lg"
      :title="$t('label.nuevo')+' '+$t('label.moduleGroup')"
      color="dark"
      :closeOnBackdrop="false"
      :show.sync="$store.state.grupomodulo.registerModal"
      class="modal-content-app-grupo"
    >
      <div class="mr-4">
        <CRow>
          <CCol sm="12">
            <CInput
              :horizontal="{label:'col-sm-12 col-lg-3',input:'col-sm-12 col-lg-8'}"
              addLabelClasses="text-right"
              :label="$t('label.subsystemType')"
              v-model="TpModuleName"  
              size="sm"     
              disabled          
            />
          </CCol>
          <CCol sm="12" v-if="(TpModuleFilterSelect.toUpperCase() === this.sigetWebId) || (TypeSubSystemSelect.toUpperCase() === this.sigetWebId)">
            <CInput
              :horizontal="{label:'col-sm-12 col-lg-3',input:'col-sm-12 col-lg-8'}"
              addLabelClasses="text-right"
              :label="$t('label.navigation')"
              v-model="TpNavigationName" 
              size="sm"     
              disabled          
            />
          </CCol>
         <CCol sm="12"  v-if="(NavigationFilterSelect.toUpperCase() === this.visitaId) || (NavigationSelect.toUpperCase() === this.visitaId)">
            <CInput
              :horizontal="{label:'col-sm-12 col-lg-3',input:'col-sm-12 col-lg-8'}"
              addLabelClasses="text-right"
              :label="$t('label.Ship')"
              v-model="VesselName" 
              size="sm"     
              disabled        
          />
          </CCol>
        </CRow>
        <CRow>
          <CCol sm="6"   v-if="(NavigationFilterSelect.toUpperCase() === this.visitaId) || (NavigationSelect.toUpperCase() === this.visitaId)">
            <CInput             
              :label="$t('label.icon')"
              addLabelClasses="required text-right"
              :horizontal="{label:'col-sm-12 col-lg-6',input:'col-sm-12 col-lg-6'}"  
              :placeholder="$t('label.icon')" 
              v-model="$v.dataModule.Icon.$model"           
              :is-valid="hasError($v.dataModule.Icon)"
              :invalid-feedback="errorMessage($v.dataModule.Icon)"
              size="sm"
              class="ml-1"
            >
            </CInput>
          </CCol>
          <CCol sm="6"  v-if="(NavigationFilterSelect.toUpperCase() === this.visitaId) || (NavigationSelect.toUpperCase() === this.visitaId)">
            <CInput              
              :label="$t('label.srcOption')"
              addLabelClasses="required text-right"
              :horizontal="{label:'col-sm-12 col-lg-4',input:'col-sm-12 col-lg-6'}"
              :placeholder="$t('label.srcOption')"
              v-model="$v.dataModule.OptionSrc.$model"
              :is-valid="hasError($v.dataModule.OptionSrc)"
              :invalid-feedback="errorMessage($v.dataModule.OptionSrc)"
              size="sm"
            >
            </CInput>
          </CCol>
        </CRow>
        <CRow>
          <CCol sm="12">
            <CInput 
              :label="$t('label.name')+' '+'(ES)'"
              addLabelClasses="required text-right"
              :horizontal="{label:'col-sm-12 col-lg-3',input:'col-sm-12 col-lg-8'}"              
              v-uppercase
              :placeholder="$t('label.registerName')+' '+'(ES)'"
              v-model="$v.dataModule.GpoModuleNameEs.$model"
              :is-valid="hasError($v.dataModule.GpoModuleNameEs)"
              :invalid-feedback="errorMessage($v.dataModule.GpoModuleNameEs)"
              size="sm"
            >
            </CInput>
          </CCol>
        </CRow>
        <CRow>
          <CCol sm="12">
            <CInput 
              :label="$t('label.name')+' '+'(EN)'"
              addLabelClasses="required text-right"
              :horizontal="{label:'col-sm-12 col-lg-3',input:'col-sm-12 col-lg-8'}"              
              v-uppercase
              :placeholder="$t('label.registerName')+' '+'(EN)'"
              v-model="$v.dataModule.GpoModuleNameEn.$model"
              :is-valid="hasError($v.dataModule.GpoModuleNameEn)"
              :invalid-feedback="errorMessage($v.dataModule.GpoModuleNameEn)"
              size="sm"
            >
            </CInput>
          </CCol>
        </CRow>
        <CRow>
          <CCol sm="12">
            <CTextarea              
              :label="$t('label.decription')"
              addLabelClasses="required text-right"
              :horizontal="{label:'col-sm-12 col-lg-3',input:'col-sm-12 col-lg-8'}"
              type="textarea"
              v-uppercase
              v-model="$v.dataModule.description.$model"
              :is-valid="hasError($v.dataModule.description)"
              :invalid-feedback="errorMessage($v.dataModule.description)"
              size="sm"
              :plaintext="false"
              :readonly="false"
              :lazy="(true,400)"
            >
            </CTextarea>
          </CCol>
        </CRow>
        <CRow>
          <CCol sm="6">
            <CSelect              
              :label="$t('label.level')"
              addLabelClasses="required text-right"
              :horizontal="{label:'col-sm-12 col-lg-6',input:'col-sm-12 col-lg-6'}"
              v-model="$v.dataModule.nivel.$model"              
              :value="LevelSelect"
              @change="LevelList($event)"
              :options="opcionesNivel"
              :is-valid="hasError($v.dataModule.nivel)"
              :invalid-feedback="errorMessage($v.dataModule.nivel)"
              size="sm"
              class="ml-1"
            >
            </CSelect>
          </CCol>
          <CCol sm="6" v-if="dataModule.nivel==2 && ModuleName == ''">
            <CSelect              
              :label="$t('label.father')"
              :class="dataModule.nivel==2  || dataModule.nivel==3 ? 'condition-label text-right' : 'text-right' "
              :horizontal="{label:'col-sm-12 col-lg-4',input:'col-sm-12 col-lg-6'}"
              v-model="$v.dataModule.padre.$model"
              :disabled="verificarPadre"
              :options="opcionesPadre"
              :is-valid="hasError($v.dataModule.padre)"
              :invalid-feedback="errorMessage($v.dataModule.padre)"
              size="sm"
            >
            </CSelect>
          </CCol>
          <CCol sm="6" v-if="(dataModule.nivel==2 || dataModule.nivel==3) && (TpModuleFilterSelect.toUpperCase() == sigetWebId) && (ModuleName!= '')">
            <CInput
              :class="dataModule.nivel==2  || dataModule.nivel==3 ? 'condition-label text-right' : 'text-right' "
              :horizontal="{label:'col-sm-12 col-lg-4',input:'col-sm-12 col-lg-6'}"
              addLabelClasses="text-right"
              :disabled="verificarPadre"
              :label="$t('label.father')"
              v-model="ModuleName" 
              size="sm"     
              disabled          
            />
          </CCol>
        </CRow>
      </div>
      <template #footer>
        <CButton
          square
          color="add"
          class="d-flex align-items-center"
          @click="registeredDateModal"
          :disabled="isSubmit"
        >
          <CIcon name="checkAlt"/>{{$t('button.accept')}}
        </CButton>
        <CButton
          square
          color="wipe"
          class="d-flex align-items-center"
          @click="setDateModal"
        >
          <CIcon name="x" /><span class="ml-1"
            >{{$t('button.cancel')}}</span
          >
        </CButton>
      </template>
    </CModalExtended>
  </div>  
</template>

<script>
import GrupoValidations from '@/_validations/grupoModulo/GrupoModuloValidations';
import ENUM from '@/_store/enum';
import { mapActions,mapState  } from 'vuex';
import UpperCase  from '@/_validations/uppercase-directive';
import ModalMixin from '@/_mixins/modal';

//data
function data(){
  return {
    usersOpened: null,
    options:[],  
    sudSystemItems:[],
    navigationItems:[],
    vesselItems:[],
    GpoModuleL1Item:[],
    WeighingItems:[],
    TypeSubSystemSelect:'',
    NavigationSelect:'',
    VesselSelect:'',
    LevelSelect:'',
    sigetWebId:'886B95BE-452A-45E0-8C11-2C162BC31AFE',  
    visitaId:'5FFE2FBD-CD0C-4B78-96B7-0C1F0F8CB97C',
    idSelect: false,
    TpModuleName:'',
    TpNavigationName:'',
    VesselName:'',
    ModuleName:'', 
    GpoModuleL1Filter:'',
    Loading:false,
    isSubmit:false,
    dataModule:{
      id:0,
      TpModuleId:'',
      TpModuleNavegationId:'',
      TpVesselId:'',
      Icon:'',
      OptionSrc:'',
      GpoModuleNameEs:'',
      GpoModuleNameEn:'',
      description:'',
      orden:1,
      nivel:0,
      padre:'',
    },
  }
}

//methods
function setRefresh () {
  this.$store.dispatch('grupomodulo/getGrupoModulolist');
}
function setDateModal () {
  this.$store.state.grupomodulo.registerModal = !this.$store.state.grupomodulo.registerModal;
}
function registeredDateModal() {
  try {
    //this.isSubmit = true;
    this.$v.$touch();
    if (this.$v.$error) {
      this.isSubmit = false;
      throw this.$t('label.errorsPleaseCheck');
    }

  let { TpModuleId,TpModuleNavegationId,TpVesselId,Icon,OptionSrc,GpoModuleNameEs,GpoModuleNameEn, description,nivel,padre,orden } = this.dataModule;
  const { dispatch } = this.$store;
  nivel = parseInt(nivel);
  if(nivel === 1){
    const itemOrdenado = this.$store.state.grupomodulo.myData.sort(function(a,b){
      return parseInt(a.Order) - parseInt(b.Order);
    });
    let ordenPadre = 0;
    let x = itemOrdenado.map(function(e) {
      return parseInt(e.Order); 
    }).indexOf(1);

    

    if(x === -1){
      orden = 1;
    }else{
      itemOrdenado.map(function(e) {
        if(e.FgActGpoModule && parseInt(e.Order) !== 0 && parseInt(e.Order) > ordenPadre 
          && parseInt(e.Level) === 1
        ){
          ordenPadre = parseInt(e.Order);
        }
        if(e.FgActGpoModule === true && parseInt(e.Level) === 1 
          && parseInt(e.Order) !== 0 && parseInt(e.Order) < ordenPadre){
          ordenPadre = parseInt(e.Order);
        }
      })
      orden = ordenPadre+1;
    }
  }else if(nivel === 2){
    let items = this.$store.state.grupomodulo.myData;
    let x = items.map(function(e) {
      return e.GpoModuleId; 
    }).indexOf(padre);
    if(x !== -1){
      const hijos = items[x].GpoModuleChildJson.sort(function(a,b){
        return parseInt(a.Order) - parseInt(b.Order);
      })
      let ordenHijo = 0;
      if(items[x].GpoModuleChildJson.length !== 0){
        let valorItem = hijos.map(function(e) {
          return parseInt(e.Order); 
        }).indexOf(1);
        if(x === -1){
          orden = 1;
        }else{
          hijos.map(function(e) {
            if(e.FgActGpoModule && e.Order !== 0 && parseInt(e.Order) > ordenHijo){
              ordenHijo = parseInt(e.Order);
            }
          })
          orden = ordenHijo + 1;
        }
      }else{
        orden = 1;
      }
    }else {
      orden = 1;
    }
  }
  dispatch('grupomodulo/postregisterGrupoModulo', { TpModuleId,TpModuleNavegationId,TpVesselId,Icon,OptionSrc,GpoModuleNameEs,GpoModuleNameEn,description,nivel,padre,orden });
  this.$emit('Update-list');
   
  } catch (e) {
    this.notifyError({text: e});
  }
}
function getSudSystemList() {
  this.$http.get("TpModuleConfigWeb-list")
    .then(response => {       
      this.sudSystemItems = response.data.data;
    }).catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    });
} 
function getVesselList() {
  this.Loading = true;
  this.$http.get("TpVessel-list")
    .then(response => {       
      this.vesselItems = response.data.data;
      this.Loading = false
    }).catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
      this.Loading = false
    });
} 
function GpoModuleL1List() {
  if (this.TpModuleNavegationId!='') { 
    this.Loading = true;
    this.$http.get("GpoModuleL1-list",{TpModuleId: this.TpModuleFilterSelect,TpModuleNavegationId:this.NavigationFilterSelect})
    .then(response => {       
      this.GpoModuleL1Item = response.data.data;
      this.Loading = false;
    }).catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
      this.Loading = false;
    });
  }else{
    this.dataModule.padre='';
    this.GpoModuleL1Item=[];
  }
}
function WeighingL1List() {
    this.Loading = true;
    this.$http.get("GpoModule-list",{TpModuleId: this.TpModuleFilterSelect,TpModuleNavegationId:this.NavigationFilterSelect,GpoModuleOrigId:''})
    .then(response => {       
      this.WeighingItems = response.data.data;
      this.Loading = false
    }).catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
      this.Loading = false;
    });
}
function LevelList(event) {
  this.dataModule.padre = '';
  this.LevelSelect = event.target.value;
  if ((this.LevelSelect==2) || (this.LevelSelect==3)) {
     if (this.NavigationFilterSelect) {
       this.GpoModuleL1List();
       this.dataModule.padre = this.ModuleSelectData;
     }else{
       this.WeighingL1List();
     }
  }else{
    this.dataModule.padre = '';
    this.WeighingItems = [];
    this.GpoModuleL1Item=[];
  }
}
//computed
function opcionesPadre(){
  let _lang = this.$i18n.locale;
  let opciones = [{
    value : 0,
    label : this.$t('label.select')
  }];
  if (this.NavigationFilterSelect!=''){
    if (this.NavigationFilterSelect==this.visitaId) {
      if(this.filterVessel.length === 0){
        return [{
          value: '', 
          label: this.$t('label.select'),
        }];
      }else{
        var chart = [{
          value: '', 
          label: this.$t('label.select'),
        }]
        this.filterVessel.map(function(e){
          chart.push({
            value: e.GpoModuleId, 
            label: _lang=='en' ? e.GpoModuleNameEn : e.GpoModuleNameEs,
          })    
        })
        return chart;
      }  
    }else{
      if(this.GpoModuleL1Item.length === 0){
        return [{
          value: '', 
          label: this.$t('label.select'),
        }];
      }else{
        var chart = [{
          value: '', 
          label: this.$t('label.select'),
        }]
        this.GpoModuleL1Item.map(function(e){
          chart.push({
            value: e.GpoModuleId, 
            label: _lang=='en' ? e.GpoModuleNameEn : e.GpoModuleNameEs,
          })    
        })
        return chart;
      }
    }  
  }else{
    this.WeighingItems.map(function(e) {
      if(parseInt(e.Level) === 1 && e.FgActGpoModule){
        opciones.push({
          value : e.GpoModuleId,
          label: _lang=='en' ? e.GpoModuleNameEn : e.GpoModuleNameEs,
        }) 
      }
    })
    return opciones;
  } 
}
function verificarPadre(){
  return ((parseInt(this.dataModule.nivel) === 2) || (parseInt(this.dataModule.nivel) === 3)) ? false : true;
}
function apiStateLoading() {
  return this.apiState === ENUM.LOADING;
}
function apiStateFormLoading() {
  let carga = false;
  if(this.apiStateForm === ENUM.LOADING || this.apiStateForm === ENUM.INIT){
    carga = !carga;
  }
  return carga;
}
function opcionesNivel(){
  
  if (this.NavigationFilterSelect!='' && this.NavigationFilterSelect==this.visitaId) {
    return[
            { value:0,label: this.$t('label.select')},
            { value:1,label: this.$t('label.level') + ' 1'},
            { value:2,label:this.$t('label.level') + ' 2'},
            { value:3,label:this.$t('label.level') + ' 3'}
        ]
  }else{
    return[
            { value:0,label: this.$t('label.select')},
            { value:1,label: this.$t('label.level') + ' 1'},
            { value:2,label:this.$t('label.level') + ' 2'},
          ]
  }
}

function vesselListFormatted(){
  if(this.vesselItems.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.vesselItems.map(function(e){
      chart.push({
        value: e.TpVesselId, 
        label: e.TpVesselName,
      })    
    })
    return chart;
  }
}  

//watch
function apiStateForm(newQuestion,OldQuestion){
  if(newQuestion === ENUM.LOADED){
    this.$store.state.grupomodulo.registerModal = false;
  }
}
function idPadre (newQuestion, oldQuestion) {
  if(newQuestion !== 0){
    this.dataModule.nivel = 2;
    this.dataModule.padre = newQuestion;
  }
}
function activarPadre(newValue,OldValue){
  if(newValue === true){
    this.$store.state.grupomodulo.registerModal = newValue;
  }
}
function registerModal(newQuestion, oldQuestion){
  if(newQuestion === false) {
    this.dataModule.id = 0;
    this.dataModule.TpModuleId='';
    this.dataModule.TpModuleNavegationId='';
    this.dataModule.TpVesselId='';
    this.dataModule.Icon='';
    this.dataModule.OptionSrc='';
    this.dataModule.GpoModuleNameEs = '';
    this.dataModule.GpoModuleNameEn = '';
    this.dataModule.description = '';
    this.dataModule.orden = 1;
    this.dataModule.nivel = 0;
    this.dataModule.padre = 0;
    this.TypeSubSystemSelect ='',
    this.NavigationSelect ='',
    this.TpModuleName = '',
    this.TpNavigationName = '',
    this.VesselName = '',
    this.ModuleName = '',
    this.sudSystemItems =[],
    this.navigationItems= [],
    this.vesselItems = [],
    this.GpoModuleL1Item= [],
    this.WeighingItems =[],
    this.VesselSelect='',
    this.LevelSelect='',
    this.GpoModuleL1Filter ='';
    this.idSelect = false;
    this.$nextTick(() => { this.$v.$reset() })
    this.$events.fire('desactivar-modal-registered', false);
  }else{
    let _lang=this.$i18n.locale;
    
    if((this.NavigationFilterSelect) && (this.visitaId==this.NavigationFilterSelect)) {
      this.VesselSelectData ? this.VesselName = this.VesselObj.TpVesselName : '';
      this.VesselSelectData ? this.dataModule.TpVesselId = this.VesselObj.TpVesselId : '';
    }
    if(this.NavigationFilterSelect){
     this.ModuleSelectData ? this.dataModule.padre = this.ModuleObj.GpoModuleId : '';
     this.ModuleSelectData ? (this.ModuleName =  _lang=='en' ? this.ModuleObj.GpoModuleNameEn : this.ModuleObj.GpoModuleNameEs): '';
    }
   
    //asigna nombre al input de tipo de subsistema
    this.TpModuleFilterSelect ? (this.TpModuleName =  _lang=='en' ? this.TpModuleObj.TpModuleNameEn : this.TpModuleObj.TpModuleNameEs): '';
    
    //asigna nombre al input de tipo de navegacion
    if(this.NavigationFilterSelect && this.NavigationFilterSelect==this.visitaId) {
      this.idSelect=true;
      this.NavigationFilterSelect ? (this.TpNavigationName = _lang=='en' ? this.navigationObj[0].TpModuleNavegationEn : this.navigationObj[0].TpModuleNavegationEs): '';
    }else{
      this.idSelect=false;
      this.NavigationFilterSelect ? (this.TpNavigationName = _lang=='en' ? this.navigationObj[1].TpModuleNavegationEn : this.navigationObj[1].TpModuleNavegationEs): '';
    }
    //igualando los selects con los input del modal
    this.dataModule.TpModuleId = this.TpModuleFilterSelect;
    this.dataModule.padre = this.ModuleSelectData;
    if (this.NavigationFilterSelect) {
      this.dataModule.TpModuleNavegationId = this.NavigationFilterSelect;
      this.dataModule.TpVesselId = this.VesselSelectData;
    }
  }
}

export default {
  name: 'app-grupo-modulo',
  props: ['idPadre', 'activarPadre','TpModuleFilterSelect', 'NavigationFilterSelect','TpModuleObj','navigationObj','filterVessel','VesselSelectData','VesselObj','ModuleObj','ModuleSelectData'],
  data,
  mixins: [
    ModalMixin,
  ],
  validations(){ return GrupoValidations(parseInt(this.dataModule.nivel), false, this.idSelect) },
  computed: {
    opcionesPadre,
    verificarPadre,
    opcionesNivel,
    apiStateLoading,
    apiStateFormLoading,
    vesselListFormatted,
    ...mapState({
      apiState: state => state.grupomodulo.apiState,
      apiStateForm: state => state.grupomodulo.apiStateForm,
      registerModal: state => state.grupomodulo.registerModal,
    })
  },
  directives: UpperCase,
  methods: {
    setDateModal,
    setRefresh,
    registeredDateModal,
    getSudSystemList,
    getVesselList,
    GpoModuleL1List,
    WeighingL1List,
    LevelList,
  },
  watch:{
    apiStateForm,
    idPadre,
    activarPadre,
    registerModal
  }
}
</script>
<style lang="scss">
.modal-content-app-grupo {
  .modal-content {
    width: 90% !important;
    margin: auto !important;
    .modal-body {
      padding-left: 1.7rem;
      padding-right: 1.7rem;
    }
  }
}

.condition-label label:before {
    color: #e55353;
    content: "* ";
    display: inline;
}




</style>
